@import "../../styles/variables";

.offline-bar {
  background: $error-state;
  color: $disabled-state-text-color;
  padding: $standard-gap 0;
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
