@font-face {
  font-family: "Gilroy-Black";
  src: url("../assets/fonts/Gilroy-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../assets/fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("../assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("../assets/fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("../assets/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("../assets/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("../assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
}
