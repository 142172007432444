@import "./styles/variables";
@import "./styles/fonts.scss";

.App {
  padding: 0 24px;
  max-width: 480px;
  margin: 0 auto calc($standard-gap * 2);
  // for non opening of dictionary
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;

  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}


.center-align {
  text-align: center;
}

.margin-auto {
  margin: 0 auto;
}

.relative-element {
  position: relative;
}

.fixed-header-body {
  margin-top: calc($standard-gap * 8);
}

.error-message {
  background-color: $error-state-background;
  color: $error-state;
  padding: calc($secondary-standard-gap / 2);
  font-family: "Gilroy-Medium";
  font-size: $tertiary-font;
}

.success-message {
  background-color: $treatment-secondary-color;
  color: $recommended-secondary-color;
  padding: calc($secondary-standard-gap / 2);
  font-family: "Gilroy-Medium";
  font-size: $tertiary-font;
}

.highlighted-icons {
  position: absolute;
  left: calc($standard-gap * 2);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0 2px 12px $drop-shadow;
}

.page-heading {
  width: 100%;
  margin-bottom: calc($standard-gap * 3);
}

.otp-help-txt {
  font-size: $secondary-font;
  margin: 16px 0 40px 0;
}

.bold {
  font-family: "Gilroy-Bold";
}

.semi-bold {
  font-family: "Gilroy-SemiBold";
}

/**
 * Added below code to override scrollbar default property
 */

.transparent-scrollbar {
  *::-webkit-scrollbar {
    background: transparent;
    /* Chrome/Safari/Webkit */
    display: none;
    width: 0px;
  }
}

// Added this code to override the green color of onetrust cookie modal button

// #onetrust-consent-sdk #onetrust-accept-btn-handler {
//   background-color: $recommended-secondary-color !important;
//   border-color: $recommended-secondary-color !important;
// }