$heading-primary-font: 24px;
$heading-secondary-font: 20px;
$heading-tertiary-font: 18px;
$primary-font: 18px;
$secondary-font: 16px;
$tertiary-font: 14px;
$desc-font: 13px;

$primary-color: #000000;
$disabled-state-text-color: #ffffff;
$disabled-state-color: #bfbfbf;
$active-state-color: #71ac48;
$recommended-primary-color: #71ac481f;
$recommended-secondary-color: #84b867;
$recommended-text-color: #feffff;
$link-color: #538235;
$modal-background: #0000001f;
$error-state: #ff0000;
$error-state-background: #ff000014;
$border-color: #dee0e2;
$accordion-border: #70707029;

$standard-gap: 12px;
$secondary-standard-gap: 16px;
$drop-shadow: #00000017;
$modal-info-color: #84b86724;
$btn-group-default-color: #f3f3f3;

$treatment-secondary-color: #84B8671F;
$treatment-recommendations-background: #BFBFBF29;
$home-treatment-border: #FBF8F2;